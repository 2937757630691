<template>
  <div class="login-wrap">
    <div id="topdiv">
      <div id="sanyuelogodiv"><img src="../assets/img/sanyueLogo.png" id="sanyuelogo"></div>
      <div id="toptextdiv">
        <div id="topzhdiv"><span id="topzhspan">三阅智慧图书馆</span></div>
        <div id="topendiv"><span id="topenspan">San<span class="nbspspan"/>Yue<span class="nbspspan"/><span
            class="nbspspan"/>Intelligent<span class="nbspspan"/><span class="nbspspan"/><span class="nbspspan"/>Library</span>
        </div>
        <!--        <div id="topendiv"><span id="topenspan">San&nbsp;&nbsp;Yue&nbsp;&nbsp;Zhi&nbsp;&nbsp;Hui&nbsp;&nbsp;Tu&nbsp;&nbsp;Shu&nbsp;&nbsp;Guan</span></div>-->
      </div>
    </div>
    <div id="picdiv">
      <img src="../assets/img/login_pic.png" id="picimg">
    </div>
    <div id="login_place">
      <div id="input_title_div"><span id="input_title_span">图书馆集群管理系统</span></div>
      <el-form :model="param" :rules="rules" ref="login" label-width="0px">
        <el-form-item prop="StrUserName" class="input_class">
          <el-input v-model="param.StrUserName" class="w-50 m-2" placeholder="请输入用户名">
            <template #prefix>
              <img src="../assets/img/username.png" style="height: 20px;width: 18px;margin-top: 9px;margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="StrPassword" class="input_class">
          <el-input class="w-50 m-2" placeholder="请输入密码" v-model="param.StrPassword" @keyup.enter="login()"
                    type="password">
            <template #prefix>
              <img src="../assets/img/passwordIcon.png"
                   style="height: 20px;width: 18px;margin-top: 9px;margin-left: 5px;">
            </template>
          </el-input>
        </el-form-item>
        <div id="input_btn" class="input_class" style="margin-top: 60px;margin-bottom: 40px">
          <el-button type="success" @click="login()">登&nbsp;录</el-button>
        </div>
      </el-form>
    </div>

    <!--    <div class="ms-login">-->
    <!--      <div class="ms-title">图书馆集群管理系统</div>-->
    <!--      <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">-->
    <!--        <el-form-item prop="username">-->
    <!--          <el-input v-model="param.StrUserName" placeholder="请输入用户名">-->
    <!--            <template #prepend>-->
    <!--              <el-button icon="el-icon-user"></el-button>-->
    <!--            </template>-->
    <!--          </el-input>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item prop="password">-->
    <!--          <el-input-->
    <!--              type="password"-->
    <!--              placeholder="请输入密码"-->
    <!--              v-model="param.StrPassword"-->
    <!--              @keyup.enter="login()">-->
    <!--            <template #prepend>-->
    <!--              <el-button icon="el-icon-lock"></el-button>-->
    <!--            </template>-->
    <!--          </el-input>-->
    <!--        </el-form-item>-->
    <!--        <div class="login-btn">-->
    <!--          <el-button type="primary" @click="login()">登录</el-button>-->
    <!--        </div>-->
    <!--      </el-form>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      param: {
        StrUserName: "",
        StrPassword: ""
      },
      rules: {
        StrUserName: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        StrPassword: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ]
      }
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    //登录
    login: function() {
      this.$refs['login'].validate((valid) => {
        if (valid) {
          this.$axios({
            url: this.localpath + 'managers/login',
            method: 'post',
            //发送格式为json
            data: JSON.stringify(this.param),
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json'
                }
          }).then((res) => {
            if (res.data.ok) {
              localStorage.setItem('token', 'Bearer ' + res.data.token)
                console.log(localStorage.getItem('token'))
              localStorage.setItem('tokentime', new Date().getTime())
              // localStorage.setItem('passWord',this.param.StrPassword)
              //获取默认的图书馆信息
              this.$axios({
                url: this.localpath + 'managers/me',
                method: 'get',
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  localStorage.setItem("defaultLibraryID", res.data.msg.StrLibraryID)
                  localStorage.setItem("StrID", res.data.msg.StrID)
                  localStorage.setItem("defaultLibraryName", res.data.msg.StrLibraryName)
                  localStorage.setItem('username', res.data.msg.StrNickName)
                  localStorage.setItem('schoolimg', res.data.msg.Avatar)
                  localStorage.setItem('route', res.data.msg.Permissions)
                  localStorage.setItem('IsAreaManager', res.data.msg.IsAreaManager)
                  localStorage.setItem('NeedMofidfyPsw',res.data.msg.NeedMofidfyPsw)
                  localStorage.setItem('libraryId',res.data.msg.StrOrganizationID)
                  localStorage.setItem('token1',res.data.token)
                  if (res.data.msg.IsAreaManager) {
                    this.$router.push('/')
                  } else {
                    this.$router.push('/dashboard')
                  }
                } else {
                  this.$notify({
                    message: res.data.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })


              // })
            } else {
              this.$message({
                message: '帐号密码错误,请重新输入',
                type: 'error'
              })
            }
          })
        }
      });
    }
  }
};
</script>
<style scoped>
/*input {*/
/*  background-color: transparent;*/
/*  border: 0;*/
/*}*/
input:focus {
  outline: none;
}

.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../assets/img/login-bg2.png) 100% no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

#topdiv {
  position: absolute;
  width: 350px;
  margin-top: 2.7%;
  margin-left: 3%;
  z-index: 999;
}

#sanyuelogodiv {
  display: inline-block;
}

#sanyuelogo {
  width: 85px;
  /*height: 47px;*/
  margin-top: 5px;
}

#toptextdiv {
  display: inline-block;
  vertical-align: top;
  margin-left: 27px;
}

.nbspspan {
  display: inline-block;
  width: 2.5%;
}

#topzhdiv {

}

#topzhspan {

  width: 210px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(38, 38, 38, 1);
  font-size: 26px;
  letter-spacing: 4px;
  font-family: AlimamaShuHeiTi;
  text-align: left;
  white-space: nowrap;
  line-height: 36px;
}

#topenspan {

  width: 211px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(38, 38, 38, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin-top: 5px;
}

#picdiv {
  position: absolute;
  bottom: -5.2%;
  left: 5%;
  margin-top: 10px;
  width: 44%;
}

#picimg {
  width: 100%;
}

#login_place {
  position: absolute;

  top: 50%;
  left: 62%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  width: 20%;
  min-width: 398px;
  margin-top: -256px;
  /*高度由内容撑开*/
  /*height: 20%;*/
}

#input_title_div {
  width: 293px;
  height: 45px;
  margin: 54px auto;
}

#input_title_span {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  letter-spacing: 1px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}

/deep/ .el-input__inner {
  border: 1px solid rgba(75, 228, 208, 1);
  border-radius: 8px;
  padding: 0 35px;
  height: 42px;
}

/deep/ .el-button--success {
  background-color: #4be4d0;
  border-color: #4be4d0;
}

/deep/ .el-button {
  width: 320px;
  border-radius: 8px;
  padding: 0;
  min-height: 42px;
}

/deep/ .el-button > span {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;

}

.input_class {
  width: 322px;
  margin: 30px auto;
}


.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  margin: -190px 0 0 -195px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
</style>
